import React from 'react';
import { createRoot } from 'react-dom/client';

import { AlertModal } from '@/src/shop/shared/components/AlertModal';

document.addEventListener('DOMContentLoaded', () => {
  const alertModalElement = document.getElementById('j-alert-modal');
  if (alertModalElement) {
    const messages: string[] = JSON.parse(alertModalElement.dataset.messages || '[]');
    const title = alertModalElement.dataset.title;
    createRoot(alertModalElement).render(<AlertModal title={title} messages={messages} />);
  }
});
